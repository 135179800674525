import React, { useState, useEffect, useCallback } from "react"
import { StaticImage } from "gatsby-plugin-image"
import Loading from "../../components/Loading"

const LoaderComponent = ({ values }: any) => {
         return (<><main className="text-center loader-center-img logo-center mx-auto">
             <StaticImage
                 className="w-24"
                 layout="constrained"
                 src="../content/cow.png"
                 alt="Purple Cow Internet 💜🐄"
             />
             <Loading loaderData={values}/>
         </main></>)
}

export default LoaderComponent;